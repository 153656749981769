import React from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import Hvezdy from "../../assets/img/hodnoceniKremace.svg";
import Check from "../../assets/img/check.svg";
import GooglePic from "../../assets/img/googleFullRating.svg";
import FirmyPic from "../../assets/img/firmyFullRating.svg";


const OverenaFirmaInner = styled.div`
  .overenaFirma {
    display: flex;

    @media (max-width: 490px) {
      display: block;
    }

    div {
      @media (max-width: 490px) {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 100%;
      }

    
      img {
        height: 14px;
        margin-left: 12px;
        margin-top: -2px;
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 14px;
        color: #000000;
      }

      .scrollLink {
        font-family: Visuelt-Medium;
        font-size: 14px;
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
        margin: 0 !important;
      }

      &:first-child {
        border-right: 2px solid #D2D2D2;
        padding-right: 15px;
        margin-right: 15px;

        @media (max-width: 490px) {
          border: 0;
          padding: 0;
          margin: 0 auto;
          margin-bottom: 10px;
        }

        img {
          height: 16px;
          margin: 0;
          margin-right: 10px;
          margin-top: -1px;

          @media (max-width: 490px) {
            height: 12px;
            margin-right: 7px;
          }
        }
      }
    }
  }

  .rating {
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    margin-top: 40px;

    @media (max-width: 899px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    @media (max-width: 540px) {
      display: block;
    } 

    img {
      display: block;
      max-height: 28px;
      max-width: 200px;
      margin-right: 30px;
      width: fit-content;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: 899px) { 
        width: fit-content;
        margin-right: 20px;
      }

      @media (max-width: 540px) {
        margin-top: 10px;

        max-height: none;
        max-width: none;
      }
    }

    div {
      border-right: 2px solid #D2D2D2;
      padding-right: 30px;
      margin-right: 30px;
      display: flex;
      align-items: center;

      img {
        height: 14px;
        margin-right: 5px;
      }

      a {
        font-family: Visuelt-Medium;
        font-size: 14px;
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;


const OverenaFirma = ({isLogos}) => {
  return (
    <OverenaFirmaInner>

      {!isLogos ?
        <div className="overenaFirma">
          <div>
            <img alt="" src={Check} />
            <span>Ověřeno zákazníky</span>
          </div>
          <div>
            <ScrollLink className="scrollLink" duration={500} smooth={true} to="reviews">Hodnocení 4,9/5</ScrollLink>
            <img alt="" src={Hvezdy} />
          </div>
        </div>
      : 
        <div className="rating">
          <div>
            <img alt="" src={Check} />
            <span><ScrollLink className="scrollLink" duration={500} smooth={true} to="reviews">Ověřeno zákazníky</ScrollLink></span>
          </div>
          <img alt="Nejlépe hodnocená pohřební služba - Google" src={GooglePic} />
          <img alt="Nejlépe hodnocená pohřební služba - Firmy.cz" src={FirmyPic} />
        </div>
      }


    </OverenaFirmaInner>
  );
}

export default OverenaFirma;


